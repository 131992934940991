<template>
  <div style="padding-top: 20px;">
    <div class="navigation-box">
      <div class="avail-contri-box">
        <div class="avail-contri-text">可用贡献值</div>
        <div class="avail-contri-value">1000</div>
      </div>
      <div class="navigation-btns">
        <router-link to="/Exchange/Record">
          <div class="navigation-btn">兑换记录</div>
        </router-link>
        <router-link to="/Exchange/AddrManage">
          <div class="navigation-btn">地址管理</div>
        </router-link>
      </div>
    </div>
    <div class="exchange-tab-box">
      <div class="exchange-tab">
        <div :class="{'exchange-tab-item': true, 'tab-selected': selectedTab == 1}" @click="changeTab(1)">实物商品</div>
        <div :class="{'exchange-tab-item': true, 'tab-selected': selectedTab == 2}" @click="changeTab(2)">虚拟商品</div>
      </div>
      <div class="exchange-content">
        <div v-for="(commodityGroup, index) in commodities" :key="index">
          <div class="exchange-item-group">
            <div class="exchange-item-group-left">
              <router-link :to="`/Exchange/Item/${commodityGroup[0].id}`">
                <div class="exchange-item long-item">
                  <div class="exchange-item-img"><img class="img" :src="commodityGroup[0].thumbnail" alt="" /></div>
                  <div class="exchange-item-info">
                    <div class="exchange-item-name">{{ commodityGroup[0].name }}</div>
                    <div class="exchange-item-price">
                      <img class="token" src='/images/Exchange/Token.png'/>
                      贡献值 {{ commodityGroup[0].price }}
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link v-if="commodityGroup.length >= 2" :to="`/Exchange/Item/${commodityGroup[1].id}`">
                <div class="exchange-item short-item">
                  <div class="exchange-item-img"><img :src="commodityGroup[1].thumbnail" alt="" /></div>
                  <div class="exchange-item-info">
                    <div class="exchange-item-name">{{ commodityGroup[1].name }}</div>
                    <div class="exchange-item-price">
                      <img class="token" src='/images/Exchange/Token.png'/>
                      贡献值 {{ commodityGroup[1].price }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="exchange-item-group-left" v-if="commodityGroup.length >= 3">
              <router-link :to="`/Exchange/Item/${commodityGroup[2].id}`">
                <div class="exchange-item short-item">
                  <div class="exchange-item-img"><img :src="commodityGroup[2].thumbnail" alt="" /></div>
                  <div class="exchange-item-info">
                    <div class="exchange-item-name">{{ commodityGroup[2].name }}</div>
                    <div class="exchange-item-price">
                      <img class="token" src='/images/Exchange/Token.png'/>
                      贡献值 {{ commodityGroup[2].price }}
                    </div>
                  </div>
                </div>
              </router-link>
              <router-link v-if="commodityGroup.length >= 4" :to="`/Exchange/Item/${commodityGroup[3].id}`">
                <div class="exchange-item long-item">
                  <div class="exchange-item-img"><img :src="commodityGroup[3].thumbnail" alt="" /></div>
                  <div class="exchange-item-info">
                    <div class="exchange-item-name">{{ commodityGroup[3].name }}</div>
                    <div class="exchange-item-price">
                      <img class="token" src='/images/Exchange/Token.png'/>
                      贡献值 {{ commodityGroup[3].price }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Notify} from 'vant';
import { getAllCommoditiesByType } from "@/api/Exchange";

export default {
  name: "Exchange",
  data() {
    return {
      commodities: [],
      selectedTab: 1
    };
  },
  methods: {
    async initData(type) {
      try {
        // get commodities info
        // const size = 4, page = 1, type = 0;
        this.commodities = [];
        let {errorCode, payload} = await getAllCommoditiesByType(type);
        if (errorCode == 200) {
          // 对商品进行四个一分组，方便排版
          let currentGroup = [];
          payload.forEach((item, index) => {
            currentGroup.push(item);
            if ((index + 1) % 4 === 0 || index === payload.length - 1) {
              this.commodities.push(currentGroup);
              currentGroup = [];
            }
          });
          
        } else {
          Notify({type: "danger", message: '获取商品信息失败'});
        }
      } catch (err) {
        console.log(err);
        Notify({type: "danger", message: '获取商品信息失败'});
      }
    },
    changeTab(index) {
      this.selectedTab = index;
      this.initData(index);
    },
  },
  mounted() {
    this.initData(1);
  }
}
</script>

<style scoped>
.navigation-box {
  display: flex;
  background: #22242f;
  width: 90%;
  height: 110px;
  margin: 0 auto;
  border-radius: 15px;
  align-items: center;
  padding: 5px 0;
  justify-content: space-between;
}

.avail-contri-box {
  width: 30%;
  padding-left: 30px;
  text-align: center;
}

.navigation-btns {
  width: 30%;
  padding-right: 30px;
}

.navigation-btn {
  background: linear-gradient(270deg, #81c4f5, #6149df);
  color: white;
  font-size: 12px;
  line-height: 25px;
  padding: 5px 0;
  height: 25px;
  border-radius: 30px;
  text-align: center;
  margin: 10px 0;
  font-weight: 900;
  cursor: pointer;
}

.avail-contri-text {
  font-size: 15px;
  color: white;
  line-height: 15px;
  margin: 5px 0;
}

.avail-contri-value {
  font-size: 35px;
  color: #7b6ce1;
  font-weight: 800;
  line-height: 35px;
}

.exchange-tab-box {
  margin: 15px auto;
  width: 90%;
}

.exchange-tab {
  display: flex;
}

.exchange-tab-item {
  width: 50%;
  text-align: center;
  color: #747070;
  font-size: 17px;
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #747070;
  cursor: pointer;
}

.tab-selected {
  color: #7859e6;
  border-bottom: 2px solid #7859e6;
}

.exchange-item-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.exchange-item-group-left {
  width: 48%;
}

.exchange-item-group-right {
  width: 48%;
}

.exchange-item {
  background: white;
  border-radius: 30px;
  margin: 20px auto;
  position: relative;
  overflow: hidden;
}

.long-item {
  height: 220px;
}

.short-item {
  height: 110px;
}

.exchange-item-img {
  height: 78%;

  .img {
    width: 100%;
    height: 100%;
  }
}

.exchange-item-info {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding-left: 15px;
  background: linear-gradient(270deg, #81c4f5, #6149df);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.exchange-item-name {
  font-size: 13px;
  color: white;
  font-weight: 900;
}

.exchange-item-price {
  font-size: 12px;
  color: #d8d8d8
}

.token {
  width: 12px
}

.exchange-content {
  height: calc(100vh - 270px);
  overflow: scroll;
}
</style>
