import request from '../utils/request';

export const getAllCommoditiesByType = type => {
    return request({
        url: process.env.VUE_APP_URL + `exchange/getAllCommodities/type/${type}`,
        method: 'get',
    });
};

export const getCommodityInfoById = id => {
    return request({
        url: process.env.VUE_APP_URL + `exchange/getCommodityInfo/commodityId/${id}`,
        method: 'get',
    });
}

export const getCommoditySpecsById = id => {
    return request({
        url: process.env.VUE_APP_URL + `exchange/getCommoditySpecs/commodityId/${id}`,
        method: 'get',
    });
}

export const getUserAddr = (id) => {
    return request({
        url: process.env.VUE_APP_URL + `exchange/getAddr`,
        method: 'get',
        params: {
            id
        }
    });
}

export const addUserAddr = (id, data) => {
    return request({
        url: process.env.VUE_APP_URL + `exchange/addAddr`,
        method: 'put',
        params: {
            id
        },
        data: data
    });
}